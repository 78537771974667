
const extraOffset = 100
const magnetism = 0.25

export default class HeaderScrollStick {
  constructor (element) {
    this._element = element
    this._lastScrollPosition = window.scrollY
    this._currentOffset = 0
    this._extraOffset = 100
    this._updateLimit()
    this._bindEventListeners()
  }
  _updateLimit() {
    const oldLimit = this._limit | 0
    this._limit = this._element.clientHeight + this._extraOffset
    this._currentOffset = (oldLimit ? this._currentOffset / oldLimit : 0) * this._limit
  }
  _updateElementTranslate(){
    this._element.style.transform = `translateY(${this._currentOffset * -1}px)`
  }
  _updateScrollDifference() {
      const scrollY = Math.max(window.scrollY, 0) 
      const difference = scrollY - this._lastScrollPosition
      const offsetDifference = difference * magnetism
      this._currentOffset = Math.min(Math.max(this._currentOffset + offsetDifference, 0), this._limit)
      this._lastScrollPosition = scrollY
      this._updateElementTranslate()
  }
  _bindEventListeners () {
    const handleScroll = 
    window.addEventListener('scroll', () => {
      this._updateScrollDifference()
    })
    window.addEventListener('resize', ()=>{
      this._updateLimit()
    })
  }
}