// Polyfill imports
import 'classlist-polyfill' // element.classList property

import 'vanilla-tilt'

// Global tooling imports
// import './vendor/modernizr-custom'
// import 'lazysizes'
// import 'lazysizes/plugins/parent-fit/ls.parent-fit.js' // Parent-fit-plugin for lazysizes, make data-sizes="auto" work well with object-fit
// import 'lazysizes/plugins/object-fit/ls.object-fit'
// import 'lazysizes/plugins/rias/ls.rias'

// App globals
// import BreakpointSubjectFactory from './lib/responsive/breakpoint-subject-factory'
// import BreakpointUtils from './lib/responsive/breakpoint-utils'
// import APP_GLOBALS from './lib/globals/global-object'

// // Assign app globals
// APP_GLOBALS.breakpoints = {
//   xs: 0,
//   sm: 576,
//   md: 768,
//   lg: 992,
//   xl: 1200,
//   xxl: 1400,
//   xxxl: 2000
// }
// APP_GLOBALS.breakpointUtils = new BreakpointUtils(APP_GLOBALS.breakpoints)
// APP_GLOBALS.breakpointSubjectFactory = new BreakpointSubjectFactory(APP_GLOBALS.breakpoints)
