import domready from 'domready'

export default function initController (selector, Constructor) {
  return new Promise((resolve, reject) => {
    domready(() => {
      const element = document.querySelector(selector)
      if (element) {
        const controller = new Constructor(element)
        resolve(controller)
      } else {
        resolve(null)
      }
    })
  })
}
