import './globals' // Contains polyfills and other globally available things
import initController from './lib/controller-helpers/init-controller'

import Modernizr from './vendor/modernizr-custom'

import HeaderScrollStick from './HeaderScrollStick'


import initTiltEffect from './initTiltEffect'

initController('.page-header', HeaderScrollStick)

initTiltEffect()