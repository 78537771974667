
import domready from 'domready';
import VanillaTilt from 'vanilla-tilt'

export default function initTiltEffect () {
  domready(()=>{
    if(!window.Modernizr.touchevents){
      const tiltItems = document.querySelectorAll('.tilt')
      ;[].forEach.call(tiltItems, (element) => {
        VanillaTilt.init(element);
      })
    }
  })
}
